@import '../../node_modules/material-design-icons/iconfont/material-icons.css';

body{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#innerDiv {
    border: 2px outset black;
    border-radius: 5px;
    display: inline-block;
    margin: 5px;
    padding: 10px;
}
#canvasContainer {
    border: 2px solid black;
    width: 800px;
    height: 600px;
    position: relative;
}
#canvas {
    background-color: lightgrey;
    cursor: crosshair;
}

#tableGrading{border:1px solid;float:left;}
#tbody{height:120px;overflow-y:auto;width:344px;}
table{border-collapse:collapse; width:100%;}
td{padding:1px 5px;
    border-right:1px solid;
    border-bottom:1px solid;}
.td1{width:15%;}
.td2{width:15%;}
.td3{width:15%;}
.td4{border-right-width:0;}

.editorButton {
    border: 2px solid black;
    background-color: lightgrey;
    color: black;
    cursor: pointer;
    border-radius: 5px;
}
.editorButton:disabled {
    border: 2px solid black;
    background-color: lightgrey;
    color: grey;
    cursor: pointer;
    border-radius: 5px;
}
.editorButton:hover {
    background-color: darkgrey;
}
.editorButton:active {
    background-color: grey;
    border: 3px solid black;
}

.material-icons.md-blue { color: rgba(0, 0, 223, 1); font-size: 48px; cursor: pointer; }
.material-icons.md-red { color: rgba(223, 0, 0, 1); font-size: 48px; cursor: pointer; }
.material-icons.md-green { color: rgba(0, 159, 0, 1); font-size: 48px; cursor: pointer; }
.material-icons.md-black { color: rgba(0, 0, 0, 1); font-size: 48px; cursor: pointer; }
.material-icons.md-grey { color: rgba(128, 128, 128, 1); font-size: 48px; cursor: default; }
.material-icons.md-greytive { color: rgba(192, 192, 192, 1); background-color: rgba(64, 64, 64, 1); font-size: 48px; cursor: pointer; }
.material-icons.md-blueish { color: rgba(0, 0, 223, 1); font-size: 48px; cursor: default; }
.material-icons.md-redish { color: rgba(223, 0, 0, 1); font-size: 48px; cursor: default; }
.material-icons.md-greenish { color: rgba(0, 159, 0, 1); font-size: 48px; cursor: default; }
.material-icons.md-blackish { color: rgba(0, 0, 0, 1); font-size: 48px; cursor: default; }
.material-icons.md-greyish { color: rgba(128, 128, 128, 1); font-size: 48px; cursor: default; }

.material-icons.md-blue:active { border: 2px solid black; background-color: lightgrey; border-radius: 5px; }
.material-icons.md-red:active { border: 2px solid black; background-color: lightgrey; border-radius: 5px; }
.material-icons.md-green:active { border: 2px solid black; background-color: lightgrey; border-radius: 5px; }
.material-icons.md-black:active { border: 2px solid black; background-color: lightgrey; border-radius: 5px; }
.material-icons.md-greytive:active { border: 2px solid black; background-color: lightgrey; border-radius: 5px; }
.material-icons.md-blueish:active { border: 0 solid white; background-color: white; border-radius: 5px; }
.material-icons.md-redish:active { border: 0 solid white; background-color: white; border-radius: 5px; }
.material-icons.md-greenish:active { border: 0 solid white; background-color: white; border-radius: 5px; }
.material-icons.md-blackish:active { border: 0 solid white; background-color: white; border-radius: 5px; }
